import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material'
import { ToDoDto, toDoValidationSchema } from 'dtos/toDoDto'
import { Formik } from 'formik'
import { ExplanationAccordion } from './_template/accordion'
import * as api from 'controllers'
import { useEffect, useState } from 'react'
import { PersonDto } from 'dtos'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'

interface TodoAddEditDialogProps {
  open: boolean
  onClose: () => void
  initialValues: ToDoDto
  onSave: (values: ToDoDto) => void
}

export default function TodoAddEditDialog({ open, onClose, initialValues, onSave }: TodoAddEditDialogProps) {
  const [teamMembers, setTeamMembers] = useState<PersonDto[]>([])

  useEffect(() => {
    api
      .getPeople({ page: 0, pageSize: 999, permission: 'TASKS', statuses: ['isUser'] })
      .then(({ value }) => {
        setTeamMembers(value)
      })
      .catch(() => {})
  }, [])
  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur
        validateOnChange
        validationSchema={toDoValidationSchema}
        onSubmit={values => {
          onSave(values)
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>{values.toDoGuid ? 'Edit' : 'Add'} To Do</DialogTitle>

                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.teamMember && errors.teamMember)}
                      helperText={touched.teamMember && errors.teamMember}
                      label='Team Member'
                      name='teamMember'
                      onChange={e => {
                        setFieldValue('teamMember', JSON.parse(e.target.value))
                      }}
                      select
                      sx={{ minWidth: 240 }}
                      value={values.teamMember ? JSON.stringify(values.teamMember) : undefined}
                    >
                      {teamMembers.map(teamMember => (
                        <MenuItem key={teamMember.id} value={JSON.stringify(teamMember)}>
                          {teamMember.firstName} {teamMember.lastName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.toDo && errors.toDo)}
                      helperText={touched.toDo && errors.toDo}
                      label='To Do'
                      name='toDo'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.toDo || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      disablePast
                      disabled={isSubmitting}
                      label='Due Date'
                      name='dueDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('dueDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('dueDate', undefined)
                        }
                      }}
                      slotProps={{
                        textField: {
                          error: Boolean(touched.dueDate && errors.dueDate),
                          helperText: touched.dueDate && errors.dueDate,
                          onBlur: handleBlur
                        }
                      }}
                      value={values.dueDate ? moment(values.dueDate) : null}
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <Grid item xs={12} mt={2}>
                <ExplanationAccordion>
                  All fields are required. ||Team Member defaults to the Team Member task list currently being viewed.||To Do
                  Item can be up to 50 characters.|| Due Date must be today or in the future.
                </ExplanationAccordion>
              </Grid>

              <DialogActions sx={{ mb: 2, mr: 2 }}>
                <Button variant='text' color='inherit' onClick={onClose}>
                  CANCEL
                </Button>

                <Button variant='contained' type='submit'>
                  SAVE
                </Button>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
