import * as yup from 'yup'
import {
  fieldTypeMessage,
  maxDecimal194Message,
  maxDecimal194Value,
  maxIntMessage,
  maxIntValue,
  maxString200Message,
  maxString200Value,
  minIntMessage,
  minIntValue,
  minPositiveDecimal194Message,
  minPositiveDecimal194Value,
  requiredFieldMessage
} from '../constants'
import { CompanyDto } from './company'
import { OpportunityDto } from './opportunity'
import { PersonDto } from './person'
import { ProjectDto, projectValidationSchema } from './project'
import { WorkOrderBillingTypeDto } from './workOrderBillingType'
import { WorkOrderInvoiceDto } from './workOrderInvoice'
import { WorkOrderReleaseDto } from './workOrderRelease'
import { WorkOrderReleaseTaskDto } from './workOrderReleaseTask'
import { WorkOrderStatusTypeDto } from './workOrderStatusType'
import { WorkOrderTypeDto } from './workOrderType'

export class WorkOrderDto {
  id?: string = undefined
  workOrderNumber?: number = undefined
  purchaseOrderNumber?: string = undefined
  billRate?: number = undefined
  devUrl?: string = undefined
  qaUrl?: string = undefined
  prodUrl?: string = undefined
  isActive: boolean = true
  isOpen: boolean = true
  closedDate?: string = undefined
  specialIdentifier?: string = undefined
  projectFolder?: string = undefined

  readonly_hoursStillNeeded?: number = undefined

  opportunity?: OpportunityDto = undefined
  salesperson?: PersonDto = undefined
  projectManager?: PersonDto = undefined
  company?: CompanyDto = undefined
  project?: ProjectDto = undefined
  companyPerson?: PersonDto = undefined
  workOrderType?: WorkOrderTypeDto = undefined
  workOrderBillingType?: WorkOrderBillingTypeDto = undefined
  workOrderStatusType?: WorkOrderStatusTypeDto = undefined
  workOrderReleases?: WorkOrderReleaseDto[] = []
  workOrderReleaseTask?: WorkOrderReleaseTaskDto[] = []
  workOrderInvoices?: WorkOrderInvoiceDto[] = []
  readonly_lastWorkOrderStatusType?: WorkOrderStatusTypeDto = undefined
  workorderActivity?: WorkOrderStatusTypeDto[] = undefined

  isOutOfHours?: boolean = undefined
  daysSinceLastActivity?: number = undefined
  fixedPriceHoursAllowed?: number = undefined
  fixedPriceHoursRemaining?: number = undefined
  prepaidHoursRemaining?: number = undefined
  openInvoicesCount?: number = undefined
}

export const workOrderValidationSchema = yup.object({
  id: yup.string().nullable(),
  company: yup.object().required(requiredFieldMessage),
  project: projectValidationSchema.required(requiredFieldMessage),
  companyPerson: yup.object().required(requiredFieldMessage),
  opportunity: yup.object().nullable(),
  workOrderType: yup.object().required(requiredFieldMessage),
  workOrderBillingType: yup.object().required(requiredFieldMessage),
  fixedPriceHoursAllowed: yup
    .number()
    .integer()
    .typeError(fieldTypeMessage('Whole Number'))
    .min(minIntValue, minIntMessage)
    .max(maxIntValue, maxIntMessage)
    .nullable(),
  billRate: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  purchaseOrderNumber: yup.string().max(maxString200Value, maxString200Message).nullable(),
  projectManager: yup.object().required(requiredFieldMessage),
  salesperson: yup.object().nullable(),
  devUrl: yup.string().max(maxString200Value, maxString200Message).nullable(),
  qaUrl: yup.string().max(maxString200Value, maxString200Message).nullable(),
  prodUrl: yup.string().max(maxString200Value, maxString200Message).nullable(),
  closedDate: yup.string().nullable(),
  specialIdentifier: yup.string().max(maxString200Value, maxString200Message).nullable(),
  projectFolder: yup.string().max(maxString200Value, maxString200Message).nullable()
})
