import { apiClient } from '_app'
import { ApiResponse } from 'dtos'
import { ToDoDto } from 'dtos/toDoDto'
import { ToDosParameters } from 'parameters'
import { URIQuery } from 'utils'

// GET  /api/to-dos
//      ?ToDosParameters -> {ToDoDto}[]
export const getToDos = (parameters: ToDosParameters) =>
  apiClient.get<ApiResponse<ToDoDto[]>>('/api/to-dos' + URIQuery(parameters)).then(response => response.data)

// POST /api/to-dos
//      {ToDoDto} -> {ToDoDto}
export const createToDos = (values: ToDoDto) =>
  apiClient.post<ApiResponse<ToDoDto>>('/api/to-dos', values).then(({ data }) => data)

// PUT  /api/to-dos
//      {ToDoDto} -> {ToDoDto}
export const updateToDo = (values: ToDoDto) =>
  apiClient.put<ApiResponse<ToDoDto>>('/api/to-dos', values).then(({ data }) => data)

// PUT  /api/to-dos/done
//      {ToDoDto} -> {ToDoDto}
export const updateToDoDone = (values: ToDoDto) =>
  apiClient.put<ApiResponse<ToDoDto>>('/api/to-dos/done', values).then(({ data }) => data)

// PUT  /api/to-dos/hide
//      {ToDoDto} -> {ToDoDto}
export const updateToHide = (values: ToDoDto) =>
  apiClient.put<ApiResponse<ToDoDto>>('/api/to-dos/hide', values).then(({ data }) => data)
