import { Add, SearchOutlined } from '@mui/icons-material'
import { Button, Grid, InputAdornment, TextField } from '@mui/material'
import LabelField from 'components/LabelField'
import { ExplanationAccordion } from 'components/_template/accordion'
import { ChipStatusSelect } from 'components/chips'
import { List, ListBody, ListFilters, ListHeader, ListRow, ListRowAction } from 'components/list'
import * as controllers from 'controllers'
import { CompanyDto, StatusCount } from 'dtos'
import { useQuery } from 'hooks'
import { CompaniesParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getStatusCount, useDebounce } from 'utils'
import CompanyAddEditDialog from './id/CompanyAddEditDialog'
import { enqueueSnackbar } from 'notistack'
import CompanyListCard from './CompanyListCard'
import { useLayout } from 'context'

const sessionStorageKey = 'companies:parameters'

export default function Companies() {
  const navigate = useNavigate()
  const query = useQuery()
  const { setTitle, setIsLayoutLoading } = useLayout()

  const defaultParameters: CompaniesParameters = {
    page: 0,
    pageSize: 10,
    search: query.get('search') ?? '',
    statuses: ['isActive'],
    activeOnly: true
  }

  const [companies, setCompanies] = useState<CompanyDto[]>([])
  const [company, setCompany] = useState<CompanyDto>(new CompanyDto())
  const [companyAddEditDialogOpen, setCompanyAddEditDialogOpen] = useState<boolean>(false)
  const [count, setCount] = useState<number>(0)
  const [isCreatingCompany, setIsCreatingCompany] = useState<boolean>(false)
  const [isGettingCompanies, setIsGettingCompanies] = useState<boolean>(true)
  const [parameters, setParameters] = useState<CompaniesParameters>(
    sessionStorage.getItem(sessionStorageKey) ? JSON.parse(sessionStorage.getItem(sessionStorageKey)!) : defaultParameters
  )
  const [statusCounts, setStatusCounts] = useState<StatusCount[]>([
    { status: 'isActive', count: 0 },
    { status: 'isInactive', count: 0 },
    { status: 'isVendor', count: 0 }
  ])

  useEffect(() => {
    setTitle('Companies')
  }, [])

  useEffect(() => {
    getCompanies(parameters)
  }, [parameters])

  const getCompanies = useDebounce((parameters: CompaniesParameters) => {
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(parameters))
    setIsLayoutLoading(true)
    controllers
      .getCompanies(parameters)
      .then(response => {
        setCompanies(response.value)
        setCount(response.totalCount ?? 0)
        setStatusCounts(response.statusCounts ?? [])
      })
      .finally(() => {
        setIsLayoutLoading(false)
      })
  }, 300)

  const findStatusCount = (status: string) => statusCounts.filter(statusCount => statusCount.status === status)[0].count

  return (
    <>
      <CompanyAddEditDialog
        initialValues={company}
        loading={isCreatingCompany}
        onClose={() => {
          setCompanyAddEditDialogOpen(false)
        }}
        onSave={values => {
          setIsCreatingCompany(true)
          controllers
            .createCompany(values)
            .then(response => {
              // TODO: Should creating a company navigate the user to the company details page?
              enqueueSnackbar('Company Created Successfully!', { variant: 'success' })
              getCompanies(parameters)
              setCompanyAddEditDialogOpen(false)
            })
            .finally(() => {
              setIsCreatingCompany(false)
            })
        }}
        open={companyAddEditDialogOpen}
      />

      <List
        pagination={{
          count,
          onPageChange: (page: number) => {
            setParameters({ ...parameters, page })
          },
          onPageSizeChange: (pageSize: number) => {
            setParameters({ ...parameters, pageSize })
          },
          page: parameters.page,
          pageSize: parameters.pageSize
        }}
      >
        <ListHeader>Companies</ListHeader>

        <ListFilters>
          <TextField
            data-search
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchOutlined fontSize='small' />
                </InputAdornment>
              )
            }}
            label='Search'
            name='search'
            onChange={e => setParameters({ ...parameters, search: e.target.value ?? undefined })}
            size='small'
            type='search'
            value={parameters.search}
          />

          <ChipStatusSelect
            label='Statuses'
            onChange={value => {
              setParameters({
                ...parameters,
                statuses: value
              })
            }}
            options={[
              {
                label: `Active (${getStatusCount(statusCounts, 'isActive')})`,
                color: 'green',
                value: 'isActive'
              },
              {
                label: `Inactive (${getStatusCount(statusCounts, 'isInactive')})`,
                color: 'gray',
                value: 'isInactive'
              },
              {
                label: `Vendor (${getStatusCount(statusCounts, 'isVendor')})`,
                color: 'purple',
                value: 'isVendor'
              }
            ]}
            value={parameters.statuses ?? []}
          />

          <Button
            color='primary'
            endIcon={<Add />}
            onClick={() => {
              setCompany(new CompanyDto())
              setCompanyAddEditDialogOpen(true)
            }}
            size='medium'
            variant='text'
          >
            ADD NEW
          </Button>
        </ListFilters>

        <ListBody>
          {companies.map(company => (
            <Grid key={company.id} item xs={12}>
              <CompanyListCard company={company} />
            </Grid>
          ))}
          <Grid item xs={12}>
            <ExplanationAccordion>
              The cards are sorted by Company Name ascending. || Search searches all fields on the card. || The Statuses
              multi-select filters the list by the selected Statuses. || The total active and inactive companies for the current
              search criteria are shown next to the Statuses multiselect filter. This helps prevent users from adding a company
              that already exists.
            </ExplanationAccordion>
          </Grid>
        </ListBody>
      </List>
    </>
  )
}
