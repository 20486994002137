import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { WorkOrderDto, WorkOrderReleaseDto, workOrderReleaseValidationSchema } from 'dtos'
import { Formik } from 'formik'
import moment from 'moment'

interface WorkOrderReleaseAddEditDialogProps {
  initialValues: WorkOrderReleaseDto
  isLoading?: boolean
  onClose: () => void
  onSave: (values: WorkOrderReleaseDto) => void
  open: boolean
  /**
   * "workOrder" can be used to determine what release types to display based on the
   * work order's work order type.
   */
  workOrder?: WorkOrderDto
  /**
   * "workOrderReleases" can be used to determine what release types to display
   * (Do not show any work order release types that already exist on the work order.)
   */
  workOrderReleases?: WorkOrderReleaseDto[]
}

export default function WorkOrderReleaseAddEditDialog({
  initialValues,
  isLoading,
  onClose,
  onSave,
  open,
  workOrder,
  workOrderReleases
}: WorkOrderReleaseAddEditDialogProps) {
  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, formikHelpers) => {
          onSave(values)
          formikHelpers.setSubmitting(false)
        }}
        validateOnBlur
        validateOnChange
        validationSchema={workOrderReleaseValidationSchema}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>{values.id ? 'Edit' : 'Add'} Release</DialogTitle>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.releaseTitle && errors.releaseTitle)}
                      helperText={touched.releaseTitle && errors.releaseTitle}
                      label='Release Title'
                      name='releaseTitle'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.releaseTitle || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      disabled={isLoading || isSubmitting}
                      label='Release Target Completion'
                      name='signOffDueDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('signOffDueDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('signOffDueDate', undefined)
                        }
                      }}
                      value={values.signOffDueDate ? moment(values.signOffDueDate) : null}
                    />
                  </Grid>

                  {/* <Grid item xs={12}>
                    <DatePicker
                      disabled={isLoading || isSubmitting}
                      label='Use Case Verbally Approved by Client'
                      name='useCaseVerballyApprovedByClientDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('useCaseVerballyApprovedByClientDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('useCaseVerballyApprovedByClientDate', undefined)
                        }
                      }}
                      value={
                        values.useCaseVerballyApprovedByClientDate ? moment(values.useCaseVerballyApprovedByClientDate) : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      disabled={isLoading || isSubmitting}
                      label='Design Spec Verbally Approved by Client'
                      name='designSpecVerballyApprovedByClientDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('designSpecVerballyApprovedByClientDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('designSpecVerballyApprovedByClientDate', undefined)
                        }
                      }}
                      value={
                        values.designSpecVerballyApprovedByClientDate
                          ? moment(values.designSpecVerballyApprovedByClientDate)
                          : null
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      disabled={isLoading || isSubmitting}
                      label='Design Spec Sign Off Sent to Client'
                      name='designSpecSignOffSentToClientDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('designSpecSignOffSentToClientDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('designSpecSignOffSentToClientDate', undefined)
                        }
                      }}
                      value={values.designSpecSignOffSentToClientDate ? moment(values.designSpecSignOffSentToClientDate) : null}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      disabled={isLoading || isSubmitting}
                      label='Design Spec Signed Off by Client'
                      name='designSpecSignedOffByClientDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('designSpecSignedOffByClientDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('designSpecSignedOffByClientDate', undefined)
                        }
                      }}
                      value={values.designSpecSignedOffByClientDate ? moment(values.designSpecSignedOffByClientDate) : null}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      disabled={isLoading || isSubmitting}
                      label='Development Complete'
                      name='developmentCompleteDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('developmentCompleteDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('developmentCompleteDate', undefined)
                        }
                      }}
                      value={values.developmentCompleteDate ? moment(values.developmentCompleteDate) : null}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      disabled={isLoading || isSubmitting}
                      label='Staging Tested by Spud'
                      name='stagingTestedBySpudDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('stagingTestedBySpudDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('stagingTestedBySpudDate', undefined)
                        }
                      }}
                      value={values.stagingTestedBySpudDate ? moment(values.stagingTestedBySpudDate) : null}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      disabled={isLoading || isSubmitting}
                      label='Release Sign Off Sent to Client'
                      name='releaseSignOffSentToClientDate'
                      onChange={newValue => {
                        if (newValue) {
                          setFieldValue('releaseSignOffSentToClientDate', newValue.format('yyyy-MM-DD'))
                        } else {
                          setFieldValue('releaseSignOffSentToClientDate', undefined)
                        }
                      }}
                      value={values.releaseSignOffSentToClientDate ? moment(values.releaseSignOffSentToClientDate) : null}
                    />
                  </Grid> */}

                  <Grid item xs={12}>
                    <TextField
                      disabled={isLoading || isSubmitting}
                      error={Boolean(touched.notes && errors.notes)}
                      helperText={touched.notes && errors.notes}
                      label='Notes'
                      name='notes'
                      multiline
                      rows={4}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.notes || ''}
                    />
                  </Grid>
                  {values.id && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.isReleaseComplete}
                            disabled={isLoading || isSubmitting}
                            name='isReleaseComplete'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.isReleaseComplete}
                          />
                        }
                        label='Release is Complete'
                      />
                    </Grid>
                  )}
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button color='inherit' disabled={isLoading || isSubmitting} onClick={onClose} variant='text'>
                  CANCEL
                </Button>

                <LoadingButton loading={isLoading || isSubmitting} type='submit' variant='contained'>
                  SAVE
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
