export const GetDesignClientSignature = 'GET_DESIGN_CLIENT_SIGNATURE'
export const GetDesignSpecVerbalApproval = 'GET_DESIGN_SPEC_VERBAL_APPROVAL'
export const GetStagingClientVerbalApproval = 'GET_STAGING_CLIENT_APPROVAL'
export const CreateUseCase = 'FLOWCHARTING'
export const UserStoryPresent = 'FLOWCHARTING_PRESENT_TO_CLIENT'
export const FlowchartingGetDocuSignApproval = 'FLOWCHARTING_GET_DOCUSIGN_APPROVAL'
export const CreateDesignSpec = 'SOFTWARE_DESIGN_WIREFRAMES'
export const WireframesPresent = 'SOFTWARE_DESIGN_WIREFRAMES_PRESENT_TO_CLIENT'
export const DesignSpecClientSignOff = 'SOFTWARE_DESIGN_WIREFRAMES_GET_VERBAL_APPROVAL'
export const StagingWireframesPresenttoClient = 'STAGING_WIREFRAMES_PRESENT_TO_CLIENT'
export const StagingWireframesGetDocuSignApproval = 'STAGING_WIREFRAMES_GET_DOCUSIGN_APPROVAL'
export const ReleasePresent = 'FRONT_END_INTEGRATION_PRESENT_TO_CLIENT'
export const ReleaseClientSignOff = 'FRONT_END_INTEGRATION_GET_DOCUSIGN_APPROVAL'
export const InternalWork = 'INTERNAL_WORK'
export const DeploytoStaging = 'PUSH_TO_STAGING'
export const DoDesignSpecInternalReview = 'DESIGN_SPEC_INTERNAL_REVIEW'
export const DeploytoProduction = 'PUSH_TO_PRODUCTION'
export const Support = 'DEVELOPING'
export const Management = 'MANAGEMENT'
export const DoDevelopmentFE = 'STAGING_WIREFRAMES'
export const FrontEndIntegration = 'FRONT_END_INTEGRATION'
export const DoDevelopmentBE = 'BACK_END_DEVELOPING'
export const DoDevelopmentBEFE = 'BACK_END_AND_FRONT_END_DEVELOPING'
export const BackEndDevelopmentInternalApproval = 'BACK_END_DEVELOPMENT_INTERNAL_APPROVAL'
export const CreateDatabaseDesign = 'DATABASE_DESIGN'
export const DesignSpecInternalApproval = 'WIREFRAMES_INTERNAL_APPROVAL'
export const DatabaseDesignInternalApproval = 'DATABASE_DESIGN_INTERNAL_APPROVAL'
export const ProjectManagement = 'PROJECT_MANAGEMENT'
export const DevelopmentInternalApproval = 'DEVELOPMENT_INTERNAL_APPROVAL'
export const DoTesting = 'TESTING'
export const VacationTime = 'VACATION_TIME'
export const GetEstimate = 'GET_ESTIMATE'
export const GetUseCaseVerbalApproval = 'GET_USE_CASE_VERBAL_APPROVAL'
export const DoDeveloperKickOffMeeting = 'DKOM'
export const DoRedlineDevelopmentAdjustments = 'REDLINE_DEVELOPMENT_ADJUSTMENTS'
export const ApproveorDeclineRedline = 'REDLINE_REVIEW'
export const SpudFacilities = 'SPUD_FACILITIES'
export const Sales = 'SALES'
export const GetReleaseClientSignature = 'GET_RELEASE_CLIENT_SIGNATURE'
export const SpudFinance = 'SPUD_FINANCE'
export const DKOM = 'DKOM'
export const CodeQualityInspection = 'CODE_QUALITY_INSPECTION'
export const CallCenter = 'CALL_CENTER'
export const SpudMarketing = 'SPUD_MARKETING'
export const SpudOperations = 'SPUD_OPERATIONS'
export const NetworkClientSupport = 'NETWORK_CLIENT_SUPPORT'
export const AssessmentReviewwithCustomer = 'ASSESSMENT_REVIEW_WITH_CLIENT'
export const SpudKPI = 'SPUD_KPI'
export const SpecQualityInspection = 'SPEC_QUALITY_INSPECTION'
export const QAPush = 'QA_PUSH'
export const Complete = 'WORK_ORDER_RELEASE_TASK_TYPES_COMPLETE'
export const ReleaseApprovedbyCustomer = 'RELEASE_APPROVED_BY_CLIENT'
export const ReviewSpecwithCustomer = 'SEND_SPEC_TO_CLIENT'
export const SpudIT = 'SPUD_IT'
export const AdHoc = 'AD_HOC'
export const SpecApprovedbyCustomer = 'SPEC_APPROVED_BY_CLIENT'
export const SpudHR = 'SPUD_HR'
export const SpudPTO = 'SPUD_PTO'
export const ProdPush = 'PROD_PUSH'
export const SpecCreation = 'SPEC_CREATION'
export const Development = 'DEVELOPMENT'
export const InformationGathering = 'INFORMATION_GATHERING'
export const SendQANoticetoCustomer = 'SEND_QA_NOTICE_TO_CLIENT'
