import * as yup from 'yup'
import { PersonDto } from './person'

export const toDoValidationSchema = yup.object({
  teamMember: yup.object().required('Team Member is required.'),
  toDo: yup.string().max(50, 'To Do cannot be more than 50 characters').required('To Do is required'),
  dueDate: yup.date().required('Due Date is required').nullable()
})

export class ToDoDto {
  toDoGuid?: string = undefined
  teamMember?: PersonDto = undefined
  toDo?: string = undefined
  dueDate?: string = undefined
  isDone?: boolean = false
  isHidden?: boolean = false
}
